
.itemcontainer {
  position: relative;
  width: 100%;

}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 60%;

}
.smallmiddle {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 50%;

}



.middle2 {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 55%;



}

.itemcontainer:hover .image {
  opacity: 1;

}

.itemcontainer:hover .middle {
  opacity: 1;
}
.itemcontainer:hover .smallmiddle {
  opacity: 1;
}

.text {
  background-color: #0f0f0f90;
  color: white;
  font-size: 12px;
  padding: 6px 22px;
  text-align: left;
}

.solout {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 23%;

}
.soldouttext {
  background-color: #27262690;
  color: white;
  font-size: 25px;
  padding: 6px 12px;
  height:100px;
  display: flex;
  justify-content: center;
  align-items: center;
}