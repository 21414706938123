



table {
    border-collapse: collapse;
    border: 0;
}

th,
td {
    border: 1px solid #aaa;
    background-clip: padding-box;
    scroll-snap-align: start;
}


thead {
    z-index: 1000;
    position: relative;
}

th,
td {
    padding: 0.6rem;
    text-align: left;
    margin: 0;
}

thead th {
    position: sticky;
    top: 0;
    background-clip: padding-box;
}

thead th.pin {
    left: 0;
    z-index: 1001;

}

tbody th {
    background-clip: padding-box;

}

tbody {
    z-index: 10;
    position: relative;
}

tbody th {
    position: sticky;
    left: 0;
}

thead th,
tbody th {
    background-color: #f8f8f8;
}